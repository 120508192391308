import React, { PureComponent } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import PhoneNumberUtil from 'lib/PhoneUtils';
import LabelValue from 'components/widgets/LabelValue';
import SearchIcon from '@material-ui/icons/Search';
import { DealStatus } from 'utils/enum/DealEnum';
import CustomerDialog from 'components/modules/customer/read/CustomerDialog';
import BasicBuyerInformationContainer from 'components/containers/widgets/customers/BasicBuyerInformationContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Paper, IconButton, Typography, Button,
} from '@material-ui/core';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import DealUtils from 'utils/DealUtils';

const styles = (theme) => ({
    paper: {
        minHeight: 250,
        maxHeight: 250,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 1px 4px rgba(0,0,0, 0.1)',
        width: '100%',
    },
    updateIcon: {
        color: theme.palette.text.purpure,
    },
    updateButton: {
        marginRight: theme.spacing(0.5),
        color: theme.palette.text.boulder,
        '&:disabled svg': {
            color: `rgba(${theme.palette.rgb.black}, 0.26)`,
        },
    },
    editIcon: {
        height: '18px',
        width: '18px',
    },
    editButton: {
        color: theme.palette.text.purpure,
    },
    boxHeader: {
        padding: theme.spacing(1, 2),
        minHeight: 38,
    },
});

class BasicBuyerInformation extends PureComponent {
    constructor(props) {
        super(props);
        const keyStore = new KeyStore();
        this.SALES_DEAL_CUSTOMER_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_CUSTOMER_WRITE);
    }

    render() {
        const {
            props: {
                classes, firstName, email, cellPhone, homePhone, workPhone, dob, ssn, dln, isBusiness,
                allowCallCellPhone, allowTextCellPhone, allowCallHomePhone, allowCallWorkPhone, customerCode, editingStatus,
                lastName, middleName, title, open, onClose, changeBuyer, saving, onOpen, onEdit, deal, clientId, customerId,
            },
        } = this;
        const { isEditing } = editingStatus;
        const name = `${firstName || ''} ${lastName || ''}`;
        const currentSSN = StringUtils.isEmpty(ssn) ? '' : '********';
        const currentDOB = DateUtils.isValid(dob) ? DateUtils.getOnlyDate(dob) : '';
        const isPostedAccount = DateUtils.isValid(deal?.postedDate);
        const disabledButtonChangeBuyer = deal?.stage === DealStatus.QUOTE || deal?.stage === DealStatus.FNI;

        const record = {
            cellPhone,
            customerId,
            dln,
            dob,
            email,
            firstName,
            middleName,
            lastName,
            homePhone,
            ssn,
            workPhone,
            isBusiness,
            allowCallCellPhone,
            allowTextCellPhone,
            allowCallHomePhone,
            allowCallWorkPhone,
        };

        return (
            <div>
                <Paper
                    elevation={0}
                    square
                    className={clsx(classes.paper)}
                >
                    <div className={clsx(classes.boxHeader, 'd-flex-center-space-between')}>
                        <div>
                            <Typography variant="h5" color="primary" className={classes.title}>{title}</Typography>
                            <Typography variant="h6" color="primary" className={classes.title}>{`Customer #${customerCode}`}</Typography>
                        </div>
                        <div>
                            {DealUtils.clientIdIsWeb(clientId) && !isPostedAccount && this.SALES_DEAL_CUSTOMER_WRITE && (
                                <>
                                    <IconButton
                                        size="small"
                                        className={classes.updateButton}
                                        disabled={(!disabledButtonChangeBuyer || isEditing)}
                                        onClick={onOpen}
                                    >
                                        <SearchIcon className={classes.updateIcon} />
                                    </IconButton>
                                    <Button
                                        size="small"
                                        startIcon={<BorderColorOutlinedIcon />}
                                        className={classes.editButton}
                                        onClick={() => onEdit(record)}
                                    >
                                        Edit
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <LabelValue
                                label="Name"
                                value={name}
                            />
                            <LabelValue
                                label="Email"
                                value={email}
                            />
                            <LabelValue
                                label="Cell Phone"
                                value={PhoneNumberUtil.formatPhone(cellPhone) || ''}
                            />
                            <LabelValue
                                label="Home Phone"
                                value={PhoneNumberUtil.formatPhone(homePhone) || ''}
                            />
                            <LabelValue
                                label="Work Phone"
                                value={PhoneNumberUtil.formatPhone(workPhone) || ''}
                            />
                        </Grid>
                        <Grid
                            item
                            className={classes.customer}
                            xs={6}
                        >
                            <LabelValue
                                label="DOB"
                                value={currentDOB}
                            />
                            <LabelValue
                                label="SSN"
                                value={currentSSN}
                            />
                            <LabelValue
                                label="DLN"
                                value={dln}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                {open && (
                    <CustomerDialog
                        open={open}
                        title="Change Buyer"
                        toggleModal={onClose}
                        onSelectRow={changeBuyer}
                        disablePrimaryButton={saving}
                    />
                )}
            </div>
        );
    }
}

BasicBuyerInformation.propTypes = {
    customerId: PropTypes.string,
    customerCode: PropTypes.string,
    dob: PropTypes.string,
    ssn: PropTypes.string,
    dln: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    lastName: PropTypes.string,
    cellPhone: PropTypes.string,
    homePhone: PropTypes.string,
    workPhone: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    deal: PropTypes.object,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }),
    open: PropTypes.bool,
    onOpen: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    changeBuyer: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    isBusiness: PropTypes.bool,
    allowCallCellPhone: PropTypes.bool,
    allowTextCellPhone: PropTypes.bool,
    allowCallHomePhone: PropTypes.bool,
    allowCallWorkPhone: PropTypes.bool,
    editingStatus: PropTypes.object,
};

BasicBuyerInformation.defaultProps = {
    customerId: '',
    customerCode: '',
    ssn: '',
    dob: '',
    dln: '',
    email: '',
    title: '',
    lastName: '',
    middleName: '',
    firstName: '',
    cellPhone: '',
    homePhone: '',
    workPhone: '',
    isBusiness: false,
    editingMode: {
        isEditing: false,
        sectionName: '',
    },
    deal: {},
    open: false,
    saving: false,
    allowCallCellPhone: false,
    allowTextCellPhone: false,
    allowCallHomePhone: false,
    allowCallWorkPhone: false,
    editingStatus: {
        isEditing: false,
        editingById: null,
        editingBy: null,
    },
};

export default withStyles(styles)(BasicBuyerInformationContainer(BasicBuyerInformation));
